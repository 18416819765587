import React from "react";
import Field from "./field.js";

const Textarea = (props) => {
  const validationFunction = (value) => {
    return value.trim() !== "";
  };
  return (<Field
    {...props}
    tag="textarea"
    defaultClassName="textarea"
    validationFunction={ validationFunction }
  />);
};

export default Textarea;
